import React, { useState, useEffect } from 'react';

import PlaceDetails from '../PlaceDetails/PlaceDetails';
import {
    getAllStores,
    getEmptySelected,
    postFilters,
    setProvinceAndCitySelected,
} from "../../store/slices/index";
import { useDispatch, useSelector } from "react-redux";
import "./List.css";
import { getCityCoords } from "../../services";
import { Link } from "react-router-dom";
import Verified from '../../svg/verification.svg';
import { CircularProgress, useMediaQuery } from '@mui/material';

const List = () => {
    const isDesktop = useMediaQuery('(min-width:600px)');
    const places = useSelector((state) => state.users?.list);
    const provinces = useSelector((state) => state.users?.allProvinces);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const provinceAndCity = useSelector(
        (state) => state.users?.provinceAndCitySelected
    );
    const city = useSelector((state) => state.users?.filtersSelected?.city);
    const [isChecked, setIsChecked] = useState(false);
    const [isCheckedList, setIsCheckedList] = useState(false);
    const [isLimit, setIsLimit] = useState(true);

    useEffect(() => {
        if (places) {
            if (places.length) {
                if (places[0].city === city) {
                    setIsLoading(false);
                }
            } else {
                setTimeout(() => {
                    setIsLoading(false);
                }, 1200);
            }
        }
    }, [places, city]);
    useEffect(() => {
        if (provinceAndCity) {
        } else {
        }
    }, []);

    const getLimit = (isLimit) => {
        return isLimit ? 100 : 350;
    };

    function handleCheckbox() {
        setIsLoading(true);
        const cityCoords = getCityCoords(provinces, city);
        const limit = getLimit(isLimit);
        dispatch(postFilters(!isChecked, city, cityCoords, limit));
        setIsChecked(!isChecked);
    }

    function handleCheckboxList() {
        setIsCheckedList(!isCheckedList);
    }
    function handleFilterCity(e) {
        if (e.target.value == '') return;
        e.preventDefault();
        setIsLoading(true);
        const cityCoords = getCityCoords(provinces, e.target.value);
        const limit = getLimit(isLimit);
        dispatch(postFilters(isChecked, e.target.value, cityCoords, limit));
        dispatch(getEmptySelected(e.target.value));
    }

    const getAllStoresFromCity = () => {
        const limit = getLimit(!isLimit);
        dispatch(getAllStores(isChecked, city, limit));
        setIsLimit(!isLimit);
    };
   
    return (
        <>
            <p className="title-list">Puntos de Venta</p>
            <div className="checkbox-container">
                <input
                    type="checkbox"
                    checked={isChecked}
                    style={{ margin: '0px' }}
                    onClick={() => {
                        handleCheckbox();
                    }}
                ></input>
                <p
                    className="sub-title-list"
                    style={{ margin: '0px', marginLeft: '5px' }}
                    onClick={() => {
                        handleCheckbox();
                    }}
                >
                    Mostrar solo estaciones que tengan nafta
                </p>
            </div>
            
            {isDesktop ? (<>
                <div className="checkbox-container">
                <input
                    type="checkbox"
                    checked={isCheckedList}
                    style={{ margin: '0px' }}
                    onClick={() => {
                        handleCheckboxList();
                    }}
                ></input>
                <p
                    className="sub-title-list"
                    style={{ margin: '0px', marginLeft: '5px' }}
                    onClick={() => {
                        handleCheckboxList();
                    }}
                >
                    {' '}
                    Ocultar lista
                </p>
            </div>
            <div
                className="select-container"
                style={{ flexDirection: 'column', paddingBottom: '1rem' }}
            >
                <p
                    className="sub-title-list"
                    style={{ margin: '0px', marginBottom: '5px' }}
                >
                    Selecciona tu provincia
                </p>
                <select
                    className="input-login"
                    style={{ height: '30px' }}
                    type="name"
                    value={provinceAndCity.province}
                    onChange={(e) => {
                        dispatch(
                            setProvinceAndCitySelected({
                                ...provinceAndCity,
                                province: e.target.value,
                            })
                        );
                    }}
                >
                    <option value="" disabled>
                        Elija una provincia
                    </option>
                    {provinces?.map((citi, i) => {
                        return (
                            <option key={i} value={citi._id}>
                                {citi.province}{' '}
                            </option>
                        );
                    })}
                </select>
            </div>
            {provinceAndCity.province.length > 0 ? (
                <>
                    <div
                        className="select-container"
                        style={{ flexDirection: 'column', paddingBottom: '1rem' }}
                    >
                        <p
                            className="sub-title-list"
                            style={{ margin: '0px', marginBottom: '5px' }}
                        >
                            Selecciona tu localidad
                        </p>
                        <select
                            className="input-login"
                            style={{ height: '30px' }}
                            type="name"
                            value={city}
                            onChange={(e) => {
                                handleFilterCity(e);
                            }}
                        >
                            <option value="">Elija una localidad</option>
                            {provinces
                                .filter(
                                    (localidad) =>
                                        localidad.province ===
                                        provinceAndCity.province
                                )[0]
                                ?.cities.map((item, j) => {
                                    return (
                                        <option key={j} value={item.city}>
                                            {' '}
                                            {item.city}{' '}
                                        </option>
                                    );
                                })}
                        </select>

                    </div>
                </>
            ) : null}</>):(<>
            <div className="checkbox-container">
                <input
                    type="checkbox"
                    checked={!isCheckedList}
                    style={{ margin: '0px' }}
                    onClick={() => {
                        handleCheckboxList();
                    }}
                ></input>
                <p
                    className="sub-title-list"
                    style={{ margin: '0px', marginLeft: '5px' }}
                    onClick={() => {
                        handleCheckboxList();
                    }}
                >
                    {' '}
                    Mostrar lista
                </p>
            </div>
            <Link to="/"  style={{ alignItems: 'flex-start', marginLeft: "1rem", display: "flex", flexDirection: "row", color: "black",fontSize:"20px"}}>
                <h6 className='sub-title-list' style={{margin:"0px"}}> Seleccionar otra localidad</h6>    
            </Link>
            <Link to="/profile"  style={{ alignItems: 'flex-start', marginLeft: "1rem", display: "flex", flexDirection: "row", color: "black",fontSize:"20px"}}>
                <h6 className='sub-title-list' style={{margin:"0px"}}> Agregar una estación que no está</h6>    
            </Link>
            </>)}
            
            {/* <button class="button-change-figuritas" style={{ marginLeft: '1rem' }}><a href="/chat" style={{ textDecoration: 'none', color: 'white' }}>Queres chatear para intercambiar figuritas?</a></button> */}
            {/* <Link to="/verified" style={{ alignItems: 'flex-start', marginLeft: "1rem", display: "flex", flexDirection: "row", color: "black" }}>
                <p style={{ margin: "0px", fontFamily: "DM Sans", fontSize: "12px" }}>Tenes un comercio y queres verificarlo?</p>
                <img src={Verified} style={{ width: "14px", marginLeft: "5px" }} alt="verified" />
            </Link> */}
            {city === 'CABA' ? <>
                <div className="checkbox-container" style={{ marginBottom: '5px', width: '275px' }}>
                    <p style={{ margin: "0px", fontFamily: "DM Sans", fontSize: "12px" }}>CABA posee más de 300 estaciones, mostrar todos puede afectar el funcionamiento de la app</p>
                </div>
                <div className="checkbox-container" style={{ marginBottom: '5px' }}>
                    <input
                        type="checkbox"
                        checked={!isLimit}
                        style={{ margin: '0px' }}
                        onClick={() => {
                            getAllStoresFromCity();
                        }}
                    ></input>
                    <p
                        className="sub-title-list"
                        style={{ margin: '0px', marginLeft: '5px' }}
                        onClick={() => {
                            getAllStoresFromCity();
                        }}
                    >
                        {' '}
                        Mostrar todos los comercios
                    </p>
                </div></>
                : null}
            {isCheckedList ? null : (
                <>
                    <div className="list-container">
                        {isLoading ? (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <CircularProgress />
                                <p>Cargando..</p>
                            </div>
                        ) : places.length ? (
                            places?.map((place, i) => (
                                <div key={i}>
                                    <PlaceDetails place={place} />
                                </div>
                            ))
                        ) : !isChecked ? (
                            <>
                                <div style={{ fontFamily: 'DM Sans' }}>
                                    {' '}
                                    Aun no hay datos en esta localidad{' '}
                                </div>
                                <Link
                                    to="/createStore"
                                    style={{
                                        textDecoration: 'none',
                                        color: 'white',
                                        fontSize: '18px',
                                        fontFamily: 'DM Sans',
                                        width: '90%',
                                    }}
                                >
                                    <button
                                        className="button-album-header"
                                        style={{ width: '80%' }}
                                    >
                                        +Registra una estación ahora
                                    </button>
                                </Link>
                            </>
                        ) : (
                            <>
                                <div style={{ fontFamily: 'DM Sans' }}>
                                    {' '}
                                    No hay estaciones que tengan nafta actualmente
                                    o no hay datos cargados en su localidad
                                </div>
                            </>
                        )}
                    </div>
                </>
            )}
        </>
    );
};

export default List;
