import React from 'react';
import './PlaceDetails.css';
import Circle from '../Circle/Circle';
import { useSelector, useDispatch } from 'react-redux';
import { getSelected, getComment } from '../../store/slices/index';
import Verified from '../../svg/verification.svg';
import { useMediaQuery } from '@mui/material';




const PlaceDetails = ({ place }) => {
    const isDesktop = useMediaQuery('(min-width:600px)');
    const { selected: select } = useSelector((state) => state.users);
    const dispatch = useDispatch();
    const globalMap = useSelector(
        (state) => state.users.globalMap
    );
    const globalMarkers = useSelector(
        (state) => state.users.globalMarkers
    );

    function handleClick() {
        dispatch(getSelected(place));
        const store_id = place.store_id;
        dispatch((dispatch) => {
            getComment(store_id, dispatch);
        });
        if(!isDesktop){
        window.scroll({
            top: 2000,
            behavior: 'smooth',
        });}
        globalMap.target.closePopup();
        globalMarkers[place.store_id].openPopup();
        //aca habia unos timeouts
    }
    function lastUpdate() {
        // Obtener la fecha de actualización en GMT-0
        const updatedDateGMT = new Date(place.updated_at);
    
        // Obtener la diferencia horaria entre GMT y GMT-3 en milisegundos (3 horas de diferencia)
        const timeZoneOffset = 3 * 60 * 60 * 1000;
    
        // Calcular la fecha de actualización en GMT-3
        const updatedDateGMTMinus3 = new Date(updatedDateGMT.getTime() - timeZoneOffset);
    
        const currentDate = new Date();
        const yesterday = new Date(currentDate);
        yesterday.setDate(currentDate.getDate() - 1);
    
        const options = { day: 'numeric', month: 'numeric', hour: 'numeric', minute: 'numeric' };
    
        if (isSameDay(updatedDateGMTMinus3, currentDate)) {
            return "hoy a las " + updatedDateGMTMinus3.toLocaleTimeString('es-AR', { hour: 'numeric', minute: 'numeric' });
        } else if (isSameDay(updatedDateGMTMinus3, yesterday)) {
            return "ayer a las " + updatedDateGMTMinus3.toLocaleTimeString('es-AR', { hour: 'numeric', minute: 'numeric' });
        } else {
            return updatedDateGMTMinus3.toLocaleDateString('es-AR', options) + " a las " + updatedDateGMTMinus3.toLocaleTimeString('es-AR', { hour: 'numeric', minute: 'numeric' });
        }
    }
    
    function isSameDay(date1, date2) {
        return date1.getDate() === date2.getDate() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getFullYear() === date2.getFullYear();
    }
    
    
    
    function isSameDay(date1, date2) {
        return date1.getDate() === date2.getDate() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getFullYear() === date2.getFullYear();
    }
    

    return (
        <div className={place?.store_id === select?.store_id ? 'box-container-selected' : 'box-container'} onClick={() => { handleClick(); }}>
            <div className='container-circle'>
                <Circle color={place.available_stock ? "green" : "red"} storeId={""}></Circle>
            </div>
            <div style={{display:"flex",flexDirection:"row",gap:'10px'}}>
                <div className='container-info'>
                    <p className='title' style={place?.name?.length > 15 ? {fontSize:"15px"}:{fontSize:'17px'}}>{place.name}</p>
                    {place?.verified ? (<p className='sub-title-place' style={{fontWeight:'1000'}}>Precio sobre: ${place?.price}</p>) : (null)}
                    <p className='sub-title-place'>{place.address}</p>
                    <p className='sub-title-place' style={{paddingTop:"10px"}}>Últ.act: {lastUpdate()}</p>
                    
                </div>
                {place?.verified && (<img src={Verified} style={{ width: "22px" }} alt="verified" />)}
            </div>
        </div>
    );




};

export default PlaceDetails;
