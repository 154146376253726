import { useState } from 'react';
import './PlaceMapDetails.css';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    getEmptySelected,
    dispatchCreateAndGetComment,
    getComment,
    dispatchUpdateAndGetStores,
    getSelected,
    setToken,
} from '../../store/slices/index';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import Report from '../../svg/danger2.svg'
import iconComment from '../../svg/comments.svg';

import axios from 'axios';
import Verified from '../../svg/verification.svg';
import { FIGUS_API_URL } from '../../constants';
import Check from '../../svg/check.svg'
import { getCityCoords } from '../../services';
import { postFilters } from '../../store/slices/index';
import Delete from '../../svg/delete.svg'
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import Login from '../Login/Login';

export default function PlaceMapDetails(place, funcion) {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.users.user.user);
    
    const sessionId = useSelector((state) => state.users.user.session_id);
   
    const [modalComentarios, setModalComentarios] = useState(false);
    const [modalChangeStateStore, setModalChangeStateStore] = useState(false);
    const [modalReport, setModalReport] = useState(false);
    const [newComment, setNewComment] = useState('');
    const comments = useSelector((state) => state.users.commentsSelected);
    const stores = useSelector((state) => state.users.list);
    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState('')
    const [buttonReportDisable, setButtonReportDisable] = useState(false)
    const [newPrice, setNewPrice] = useState('')
    const provinces = useSelector((state) => state.users.allProvinces);
    const provinceAndCity = useSelector(
        (state) => state.users.provinceAndCitySelected
    );
    const city = useSelector((state) => state.users.filtersSelected.city);
    function usuarioDueño(){
        if(user?.mail === place?.place?.verified_owner_mail){
            return true
        }return false
    }
    function isAdmin(){
        if(user?.role == 4){
            return true
        }return false
    }
    async function handleDeleteStore(){
        try {
            await axios.put(
            `${FIGUS_API_URL}/stores/toggle/${place.place.store_id}?session_id=${sessionId}&active=false`
            );
            toast.success("Se ha borrado con exito la tienda");
        } catch {
            toast.error("Ha ocurrido un error");
        }
    }
    function handleDeleteComment(e, comment_id) {
        e.preventDefault()
        axios.put(`${FIGUS_API_URL}/comments/toggle/${comment_id}?session_id=${sessionId}&active=false`).then((response) => {
            toast.success('El comentario se ha eliminado con exito')
            const store_id = place?.place.store_id;
                dispatch((dispatch) => {
                    getComment(store_id, dispatch);
                });
                
            
        })


    }


    const navigate = useNavigate()
    function subtractHours(numOfHours, date = new Date()) {
        date.setHours(date.getHours() - numOfHours);

        return date;
    }
    function handleDate(originalDate) {
        const date = new Date(originalDate)
        const fecha = subtractHours(3, date)
        const fechaColoquial = new Intl.DateTimeFormat('es-AR', { dateStyle: 'full', timeStyle: 'short' }).format(fecha)
        return fechaColoquial
    }
    function handleClosePlaceMap(e) {
        e.preventDefault();
        dispatch(getEmptySelected());
    }
    function handleNewPrice(e) {
        e.preventDefault();
        if (Number.isInteger(Number(newPrice))) {
            if (Number(newPrice) < 400 && Number(newPrice) > 150) {
                
                const lugar = place
                
                axios.put(`${FIGUS_API_URL}/stores/price/${place.place.store_id}?price=${newPrice}&session_id=${sessionId}`)
                toast.success(`El precio ha cambiado satisfactoriamente a $${newPrice}`)
                setNewPrice('')
                setTimeout(() => {
                    const cityCoords = getCityCoords(provinces, city);
                    dispatch(postFilters(false, city, cityCoords));
                    
                }, 1000)
                // setTimeout(()=>{

                //     dispatch(getSelected(lugar?.place));
                // },1000)
            } else {
                toast.error('Por favor ingrese un valor correcto')
            }
        } else {
            toast.error('Por favor ingrese un valor numerico sin decimales')
        }


    }
    function handleSendComment(e) {
        e.preventDefault();
        if (newComment === '' || newComment?.length < 4) {
            toast.error('Por favor extienda su comentario');
        } else {
            const commentAndStore = {
                store_id: place?.place?.store_id,
                comment: newComment,
            };
            dispatch(
                dispatchCreateAndGetComment(
                    place?.place?.store_id,
                    sessionId,
                    commentAndStore,
                    toast
                )
            );
            setNewComment('');
        }
    }
    function handleChangeStockStore(e) {

        e.preventDefault();
        const storeId = place?.place?.store_id;

        dispatch(
            dispatchUpdateAndGetStores(
                storeId,
                !place.place.available_stock,
                sessionId,
                toast,
                stores
            )
        );

        setModalChangeStateStore(false);
        // dispatch(getAllStores());
    }
    function handleChangeNewComment(e) {
        e.preventDefault();
        if (newComment?.length < 75 || e.target.value?.length < 75) {
            setNewComment(e.target.value);
        } else {
            toast.error('Su comentario ha llegado al maximo de caracteres');
        }
    }
    function handleSentReport(e) {
        e.preventDefault()
        if (subject.length < 2) {
            toast.error('Por favor seleccione un motivo de reporte')
        } else if (message.length < 5) {
            toast.error('Por favor detalle el motivo del reporte')
        } else {
            setButtonReportDisable(true)
            axios.post(`${FIGUS_API_URL}/issues?session_id=${sessionId}`, { message: message, subject: subject, store_id: place.place.store_id })
            toast.success('Su reporte ha sido enviado correctamente')
            setTimeout(() => {
                setModalReport(false)
            }, 1000)
        }
    }
    
    return (
        <>
            {modalChangeStateStore ? (
                user?.name ? (
                    user?.verified ? (
                        <>
                            <div className="modal-container">
                                <button
                                    className="close-place-map"
                                    style={{ fontSize: '24px' }}
                                    onClick={() =>
                                        setModalChangeStateStore(
                                            !modalChangeStateStore
                                        )
                                    }
                                >
                                    X
                                </button>

                                <p className="text-modal">
                                    Con esta accion usted confirmara que en{' '}
                                    <strong>{place.place.name}</strong>{' '}
                                    {place.place.available_stock ? (
                                        <>
                                            no hay nafta{' '}
                                            <FontAwesomeIcon
                                                fontSize={18}
                                                color={'red'}
                                                icon={faTimes}
                                            />{' '}
                                        </>
                                    ) : (
                                        <>
                                            hay nafta{' '}
                                            <FontAwesomeIcon
                                                icon={faCheck}
                                                fontSize={18}
                                                color={'green'}
                                            />
                                        </>
                                    )}
                                    .
                                </p>
                                <p
                                    className="text-modal"
                                    style={{ marginTop: '0' }}
                                >
                                    Desea continuar?{' '}
                                </p>

                                <div className="buttons-modal-container">
                                    <button
                                        className="button-cancel-modal"
                                        onClick={() =>
                                            setModalChangeStateStore(
                                                !modalChangeStateStore
                                            )
                                        }
                                    >
                                        Cancelar
                                    </button>
                                    <button
                                        className="button-accept-modal"
                                        onClick={(e) => handleChangeStockStore(e)}
                                    >
                                        Aceptar
                                    </button>
                                </div>
                            </div>
                        </>) : (
                        <div className="modal-container">
                            <button
                                className="close-place-map"
                                style={{ fontSize: '24px' }}
                                onClick={() =>
                                    setModalChangeStateStore(!modalChangeStateStore)
                                }
                            >
                                X
                            </button>
                            <p className="text-modal">
                                Para cambiar el estado de una tienda primero debe
                                verificar su e-mail
                            </p>
                            <div className="buttons-modal-container">
                                <button
                                    className="button-cancel-modal"
                                    onClick={() =>
                                        setModalChangeStateStore(
                                            !modalChangeStateStore
                                        )
                                    }
                                >
                                    Cancelar
                                </button>
                                <Link to="/misdatos">
                                    <button
                                        className="button-accept-modal"
                                        style={{ width: '150px' }}
                                    >
                                        Verificar e-mail
                                    </button>
                                </Link>
                            </div>
                        </div>
                    )
                ) : (
                    <div className="modal-container">
                        <button
                            className="close-place-map"
                            style={{ fontSize: '24px' }}
                            onClick={() =>
                                setModalChangeStateStore(!modalChangeStateStore)
                            }
                        >
                            X
                        </button>
                        <p className="text-modal">
                            Para cambiar el estado de una tienda primero debe
                            iniciar sesion
                        </p>
                        
                            <Login/>
                        
                    </div>
                )
            ) : null}
            {modalComentarios ? (
                <div className="modal-container-comments">
                    <button
                        className="close-place-map"
                        style={{ fontSize: '24px' }}
                        onClick={() => setModalComentarios(!modalComentarios)}
                    >
                        X
                    </button>
                    <p className="title-modal">{place.place.name}</p>
                    {comments[0] ? (
                        <div className="container-comments-modal">

                            {comments?.map((comment, i) => (
                                <div
                                    key={i}
                                    className="container-one-comment-modal"
                                >
                                    <div style={{ display: 'flex' }}>
                                        <div className="profile-picture-comment-modal">
                                        <p>
                                                {comment.user_name[0].toUpperCase()}
                                            </p>

                                            <p>
                                                {' '}
                                                {comment.user_last_name[0].toUpperCase()}
                                            </p>
                                        </div>

                                        <div className="user-info-comment-modal">
                                            <div style={{ display: "flex", flexDirection: "row", gap: '15px', justifyContent: "space-between", alignItems: "center" }}>
                                                <p className="user-comment-modal">
                                                    {comment.user_name}{' '}
                                                    {comment.user_last_name}
                                                </p>
                                                {(place?.place?.verified && (usuarioDueño())) || (isAdmin())
                                                    ? (
                                                        <button className="delete-comment"

                                                            onClick={(e) => { handleDeleteComment(e, comment?.comment_id) }} style={{display:"flex",alignItems:"center"}}><p style={{margin:"0px",fontSize:"12px",fontFamily:"DM Sans"}}>Eliminar</p><img src={Delete} alt="delete-comment" style={{ width: '20px' }}></img></button>
                                                    ) : (null)}
                                            </div>
                                            <p className="date-comment-modal">
                                                {handleDate(
                                                    comment.updated_at
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                    <p className="comments-modal">
                                        {comment.comment}
                                    </p>

                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="container-comments-modal-no-comments">
                            <p className="text-modal-no-comments">
                                Aun no hay comentarios, sé el primero!
                            </p>
                        </div>
                    )}


                    {user?.name ? (
                        user?.verified ? (
                            <form
                                onSubmit={(e) => {
                                    handleSendComment(e);
                                }}
                            >
                                <div className="send-comment-modal-container">
                                    <input
                                        className="input-comment-modal"
                                        placeholder="Escribi tu comentario.."
                                        value={newComment}
                                        onChange={(e) => {
                                            handleChangeNewComment(e);
                                        }}
                                    ></input>
                                    <input
                                        className="button-comment-modal"
                                        type="submit"
                                        value="Enviar"
                                    />
                                </div>
                            </form>) : (
                            <div className="send-comment-modal-container">
                                {/* <button className='button-cancel-modal' onClick={()=>setModalComentarios(!modalComentarios)}>Cancelar</button> */}
                                <Link to="/misdatos">
                                    <div
                                        className="button-accept-modal"
                                    >
                                              <p className="text-modal">
                                                Para comentar primero debe iniciar sesión
                                            </p>
                                        <Login></Login>
                                    </div>
                                </Link>
                            </div>

                        )
                    ) : (
                        <div className="send-comment-modal-container">
                            {/* <button className='button-cancel-modal' onClick={()=>setModalComentarios(!modalComentarios)}>Cancelar</button> */}
                            <div
                                        style={{ width: '230px',display:"flex",flexDirection:"column"}}
                                    >
                                              <p className="text-modal">
                                                Para comentar primero debe iniciar sesión
                                            </p>
                                        <Login></Login>
                                    </div>
                        </div>
                    )}
                </div>
            ) : null}
            {modalReport ? (
                sessionId ? (
                    <>
                        <div className="modal-container-report" style={{ height: "500px", justifyContent: "flex-start" }}>
                            <button
                                className="close-place-map"
                                style={{ fontSize: '24px' }}
                                onClick={() =>
                                    setModalReport(
                                        !modalReport
                                    )
                                }
                            >
                                X
                            </button>

                            <p className="text-modal" style={{ margin: "0px", marginTop: "25px" }}>
                                Por que quiere reportar el negocio:
                                <p style={{ margin: "0px" }}><strong>{place.place.name} ?</strong></p>
                            </p>


                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="female"
                                name="radio-buttons-group"
                                onChange={(e) => { setSubject(e.target.value) }}
                            >
                                <FormControlLabel value="direccion incorrecta" control={<Radio />} label="La direccion es incorrecta" />
                                <FormControlLabel value="titulo incorrecto" control={<Radio />} label="Tiene un titulo incorrecto " />
                                <FormControlLabel value="no existe" control={<Radio />} label="No existe" />
                                <FormControlLabel value="otro" control={<Radio />} label="Otro" />
                            </RadioGroup>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <label style={{ fontSize: "15px" }}>Detalle el motivo</label>
                                <textarea onChange={(e) => setMessage(e.target.value)} style={{ resize: "none", width: "250px", height: "50px" }}></textarea>

                            </div>

                            <div className="buttons-modal-container">
                                <button
                                    className="button-cancel-modal"
                                    onClick={() =>
                                        setModalReport(
                                            !modalReport
                                        )
                                    }
                                >
                                    Cancelar
                                </button>
                                <button
                                    className="button-accept-modal"
                                    disabled={buttonReportDisable}
                                    onClick={(e) => handleSentReport(e)}
                                >
                                    Aceptar
                                </button>

                            </div>
                        </div>
                    </>
                ) : (
                    <div className="modal-container">
                        <button
                            className="close-place-map"
                            style={{ fontSize: '24px' }}
                            onClick={() =>
                                setModalReport(!modalReport)
                            }
                        >
                            X
                        </button>
                        <p className="text-modal">
                            Para reportar una estación primero debe iniciar sesión
                        </p>
                        <Login></Login>
                    </div>
                )
            ) : null}
            <>
                <div className="img-place-map">
                    <img
                        src={place.place.image_url}
                        alt={place.place.name}
                    ></img>

                </div>
                <div className="info-place-map">
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '1rem',
                        }}
                    >
                        <p
                            className="title-place-map"
                            style={
                                place?.place.name.length > 15
                                    ? { fontSize: '12px' }
                                    : { fontSize: '14px' }
                            }
                        >
                            {place.place.name}
                        </p>
                        {place?.place?.verified ? (<img src={Verified} style={{ width: "18px" }} alt="verified" />) : (null)}
                        <div style={{ position: 'relative' }}>
                            <img
                                src={iconComment}
                                className="comment-place-map"
                                alt="comment"
                                onClick={() => setModalComentarios(true)}
                            ></img>
                            {comments?.length > 0 ? (
                                <div className="red-point">
                                    <label
                                        style={{
                                            color: 'white',
                                            fontFamily: 'DM Sans',
                                            fontSize: '8px',
                                        }}
                                    >
                                        {comments?.length}
                                    </label>
                                </div>
                            ) : (
                                <div className="red-point-small"></div>
                            )}
                        </div>

                    </div>
                    <p className="sub-title-place-map">
                        {place.place.store_type.toUpperCase()}

                    </p>
                    {place?.place?.verified ? (<p className='sub-title' style={{ fontWeight: '1000', margin: '0px' }}>Precio sobre: ${place?.place?.price}</p>) : (null)}
                    {place?.place?.verified && ((usuarioDueño()) || (isAdmin())) ? (
                        <form onSubmit={(e) => { handleNewPrice(e) }} style={{ display: 'flex', flexDirection: "row", margin: "5px", gap: "10px", backgroundColor: "white", border: "1px solid black", padding: "5px", borderRadius: "5px" }}>
                            <input placeholder='Ingrese nuevo precio' className="input-change-price" value={newPrice} onChange={(e) => { setNewPrice(e.target.value) }}></input>
                            <button type="onSubmit" style={{ cursor: "pointer" }} onClick={(e) => { handleNewPrice(e) }} className="button-change-price">Cambiar</button>
                        </form>
                    ) : (
                        null
                    )

                    }
                    <p className="adress-place-map">{place.place.address}</p>
                    
                    {/* Si está verificado el comercio y el usuario es el dueño o rol 4, podes cambiar el estado. O si no esta verificado, cualquiera puede cambiar el estado */}
                    {((place?.place?.verified && ((usuarioDueño()) || (isAdmin())))
                    || (!place?.place?.verified)) ? (
                        
                        place.place.available_stock ? (
                        <p
                            onClick={() =>
                                setModalChangeStateStore(!modalChangeStateStore)
                            }
                            className="adress-place-map"
                            style={{ color: '#4452b8', cursor: 'pointer' }}
                        >
                            No hay nafta? Cambiar estado
                        </p>
                    ) : (
                        <p
                            className="adress-place-map"
                            style={{ color: '#4452b8', cursor: 'pointer' }}
                            onClick={() =>
                                setModalChangeStateStore(!modalChangeStateStore)
                            }
                        >
                            Hay nafta? Cambiar estado
                        </p>
                    )) : (
                        place.place.available_stock ? (
                            <p className="available-place-map">
                                <FontAwesomeIcon
                                    icon={faCheck}
                                    fontSize={18}
                                    color={'green'}
                                />{' '}
                                Hay nafta
                            </p>
                        ) : (
                            <p className="available-place-map">
                                <FontAwesomeIcon
                                    fontSize={18}
                                    color={'red'}
                                    icon={faTimes}
                                />{' '}
                                No hay nafta
                            </p>
                    ))}


                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "flex-start", gap: "2px" }} onClick={() => setModalReport(true)}>

                        <img
                            src={Report}
                            className="comment-place-map"
                            alt="report"
                            style={{ width: "14px" }}

                        ></img>
                        <p className="adress-place-map" style={{ color: 'black', cursor: 'pointer', fontSize: "12px" }}>Reportar estación</p>
                    </div>
                        {isAdmin() && (<button className="delete-comment" onClick={(e) => { handleDeleteStore(e) }} style={{display:"flex",alignItems:"center"}}><p style={{margin:"0px",fontSize:"12px",fontFamily:"DM Sans"}}>Eliminar</p><img src={Delete} alt="delete-comment" style={{ width: '20px' }}></img></button>)}
                </div>
            </>
            <Toaster position='top-center' />
        </>
    );
}

