import Home from './components/Home/Home';
import { useState, useEffect } from 'react';
import {Routes, Route} from 'react-router-dom'
import Profile from './components/Profile/Profile';
import { CreateUser } from './components/CreateUser/CreateUser';
//redux
import {getAllStores,postLoginLocal} from './store/slices/index'
import {useDispatch,useSelector} from 'react-redux'
import LandingPage from './components/LandingPage/LandingPage';
import CreateStore from './components/CreateStore/CreateStore';
import HowTo from './components/HowTo/HowTo';
import Album from './components/Album/Album';
import MisDatos from './components/MisDatos/MisDatos';
import Recovery from './components/Recovery/Recovery';
import './App.css'
import Admin from './components/Admin/Admin';
import Verified from './components/Verified/Verified';
import Chat from './components/Chat/Chat';
import Login from './components/Login/Login';

function App() {
  const dispatch = useDispatch()

  
  useEffect(() => {
    
      dispatch(getAllStores());
    
  }, [dispatch])

  useEffect(() => {
    let inicioSesion = JSON.parse(localStorage.getItem("userData"));
    if (inicioSesion) {
      dispatch(postLoginLocal(inicioSesion))
      }
}, [dispatch]);

const isSessionActive = (expiry_at) => {
  return new Date(expiry_at) > new Date()
}








  return (
    <>
    <Routes>
      <Route path="/" element={<LandingPage/>}> </Route>
      <Route path="/home" element={<Home/>}> </Route>
      <Route path="/login" element={<Login/>}> </Route>
      <Route path="/profile" element={<Profile/>}> </Route>
      <Route path="/createStore" element={<CreateStore/>}> </Route>
      <Route path="/howTo" element={<HowTo/>}> </Route>
      <Route path="/album" element={<Album/>}> </Route>
      <Route path="/misDatos" element={<MisDatos/>}> </Route>
      <Route path="/login/recovery" element={<Recovery/>}> </Route>
      <Route path="/admin" element={<Admin/>}> </Route>
      <Route path="/verified" element={<Verified/>}> </Route>
      <Route path="/chat" element={<Chat/>}> </Route>
      
    </Routes>
      
    </>
  );
}

export default App;

