import React from "react";
import axios from "axios";
import StoreIssue from "./StoreIssue";
import { FIGUS_API_URL } from "../../constants";
import { useSelector } from "react-redux";

const Issue = ({ issue, store,handleRevisado}) => {
    const issueTitleStyle = {
    color: "grey",
    padding: "0px",
    margin: "0px"
  };

  return (
    <div className="box-container-issue">
      <button onClick={()=>{handleRevisado(issue.issue_id)}}>Pasar a revisado</button>
      <div>
        <h5 style={issueTitleStyle}>{issue?.subject?.toUpperCase()}</h5>
        {issue?.message?.length ? (
          <h4 style={{ padding: "0px", margin: "0px" }}>Problema: {issue?.message}</h4>
        ) : (
          <h4> Descripcion: No hay descripcion</h4>
        )}
        <h5 style={{ padding: "0px", margin: "0px" }}> By {issue?.mail}</h5>
      </div>
      <div className="container-store-issue">
        <StoreIssue store={store} />
      </div>
    </div>
  );
};

export default Issue;
