import Header from "../Header/Header"
import './HowTo.css'
export default function HowTo(){
    return(
        <>
        <Header/>
        <div className="container-howto">
        <h1>Tanque lleno</h1>
        <p>Tanque lleno reúne las </p>
        <p>En la lista y en el mapa mostramos todos los negocios que los usuarios fueron aportando en donde alguna vez se vendieron figuritas.</p>
            <img className="img-howto" src="https://i.postimg.cc/pT27npJT/mapa-completo.png" alt="mapa completo"></img>
            <p>A la izquierda se encuentran los filtros, pudiendo seleccionar ciudad, y mostrar solo los estaciones que esten cargando</p>
            <div className="container-img">
                <img className="img-howto" src="https://i.postimg.cc/Hn4ZBHWx/disponibles.png" alt="filtro disponible"></img>
                <img className="img-howto" src="           https://i.postimg.cc/qqdDsgTS/ciudades.png" alt="filtro ciudades"></img>
            </div>
 
            <p>
            Si el circulo se visualiza en rojo quiere decir que alguien actualizo el estado y que actualmente no estan teniendo stock. El estado se puede actualizar presionando en el final de la tienda donde dice cambiar estado. Cualquier persona con una cuenta puede cambiar el estado de un negocio</p>
            <div className="container-img">
                <img className="img-howto" src="https://i.postimg.cc/rsRvq79C/Jumbo-en-rojo.png" alt="mapa completo"></img>
                <img className="img-howto" src="https://i.postimg.cc/0N2tnhh5/estado.png" alt="mapa completo"></img>
            </div>
            
            
           <p> Al cambiar el estado de un negocio, el circulo aparecerá en verde, queriendo decir que actualmente hay stock de figuritas</p>
            <img className="img-howto" src="https://i.postimg.cc/MKPd99bz/jumbo-verde.png" alt="mapa completo"></img>
            <p>Esto quiere decir que hay stock ahora que lo veo verde? No, pero te da un parametro de alguien que obtuvo informacion en ese momento. Es decir, si alguien actualizo que a las 13:00 habia stock, no quiere decir que a las 15:00 vas a poder encontrar figuritas, ya que puede que nadie de la comunidad haya pasado por ese negocio, o que nadie haya actualizado el estado.</p>
            <p>
            Además en cada negocio vas a poder dejar un comentario aportando mas detalle a la comunidad, presionando en el icono de comentario. Cuando haya un numero significa que ya hay comentarios previos
            Los comentarios se veran de la siguiente manera: 
            </p>

            <div className="container-img">
                <img className="img-howto" src="https://i.postimg.cc/1tF3j7N2/comentarios.png" alt="mapa completo"></img>
                
                <img className="img-howto"  src="https://i.postimg.cc/BnZcfHpM/comentarios-hechos.png" alt="mapa completo"></img>
            </div>

        </div>
        </>
    )
}