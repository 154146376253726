import React from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Logo from "../../svg/dondecargo.png";
import { Toolbar, useMediaQuery } from "@mui/material";
import Login from "../Login/Login";

const Header = () => {
  const isDesktop = useMediaQuery("(min-width:600px)");
  const user = useSelector((state) => state.users.user.user);

  return (
    <>
      <Toolbar
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "#005090",
        }}
      >
        <Link
          to="/home"
          style={{
            textDecoration: "none",
            color: "white",
            fontSize: "22px",
            fontFamily: "DM Sans",
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src={Logo}
            alt="logo"
            style={{ height: isDesktop ? '65px': user?.picture ? "60px":"45px", padding: isDesktop ? "0.8rem":"1.2rem" }}
          ></img>
          {/* <p style={{width:"120px",margin:"0px",marginLeft:"5px"}}>Tanque lleno</p> */}
        </Link>

        {isDesktop ? (
          <>
            {/* DESKTOP */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {user?.name ? (
                <>
                  {/* Si hay usuario logeado */}
                  {/* <Link to='/album' style={{textDecoration:"none",color:"white",fontSize:"18px",marginRight:"2rem", fontFamily:"DM Sans"}}><button className='button-album-header'>Completá tu album!</button></Link> */}
                  <Link
                    to="/createStore"
                    style={{
                      textDecoration: "none",
                      color: "white",
                      fontSize: "18px",
                      marginRight: "2rem",
                      fontFamily: "DM Sans",
                    }}
                  >
                    <p>+ Registra una estación!</p>
                  </Link>
                  <p
                    style={{
                      marginRight: "1rem",
                      fontSize: "18px",
                      fontFamily: "DM Sans",
                      color: "white",
                    }}
                  >
                    Hola {user?.name}!
                  </p>
                  {user.picture ? (
                    <div className="profile-picture-profile">
                      <Link
                        to="/profile"
                        style={{ textDecoration: "none" }}
                        className="profile-picture-header"
                      >
                        <img alt="profile" src={user.picture}></img>
                      </Link>
                    </div>
                  ) : (
                    <div className="container-user-profile">
                      <div className="profile-picture-profile" alt="">
                        <Link
                          to="/profile"
                          style={{ textDecoration: "none" }}
                          className="profile-picture-header"
                        >
                          <p>{user?.name[0].toUpperCase()}</p>
                          <p> {user?.last_name[0].toUpperCase()}</p>
                        </Link>
                      </div>
                      <h3>
                        {user?.name} {user?.last_name}
                      </h3>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {/* Si no hay usuario logeado */}

                  <Login></Login>
                </>
              )}
            </div>
          </>
        ) : (
          <>
            {/* MOBILE */}
            {user?.name ? (
              <div style={{display:"flex",flexDirection:"column",gap:"3px",justifyContent:"center",alignItems:'center'}}>
                <div className="profile-picture-profile" style={{margin:"0px"}}>
                  <Link
                    to="/profile"
                    style={{ textDecoration: "none" }}
                    className="profile-picture-header"
                  >
                    <img alt="profile" src={user?.picture}></img>
                    
                  </Link>
                </div>
                  <p style={{margin:"0px",color:"white",fontFamily: "DM Sans",}}>Hola {user?.name}!</p>
              </div>
            ) : (
              <>
                {/* Si no hay usuario logeado */}
                <Login/>
              </>
            )}
          </>
        )}
      </Toolbar>
    </>
  );
};

export default Header;
