import Logo from '../../svg/tanquellenoazulchico.png'
import './LandingPage.css'
import { Link } from 'react-router-dom'
import { postFilters, getAllProvinces, setProvinceAndCitySelected } from '../../store/slices/index'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { FIGUS_API_URL } from '../../constants'
import { useSelector } from 'react-redux'
import { getCityCoords } from '../../services'
import { CircularProgress } from '@mui/material'

// interface DefaultLocation {
//     latitude: number;
//     longitude: number;
// }

// interface City {
//     city: string;
//     city_id: string;
//     default_location: DefaultLocation;
// }

// interface CitiesData {
//     cities: City[];
//     province: string;
// }

export default function LandingPage() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [province, setProvince] = useState('')
    const [city, setCity] = useState('')
    const provinces = useSelector(state => state.users?.allProvinces)

    useEffect(() => {
        axios.post(`${FIGUS_API_URL}/analytics/visit`);
        getAllProvinces(dispatch);
    }, [])

    function handleSelectCity(city) {
        if (city === '') {
            toast.error('Por favor seleccione una provincia')
        } else {
            const cityCoords = getCityCoords(provinces, city)
            dispatch(setProvinceAndCitySelected({ province, city }))
            dispatch(postFilters(false, city, cityCoords, 100))
            axios.post(`${FIGUS_API_URL}/analytics/visit-city?city=${city}`).then(() => { })
            navigate('/home')

        }

    }
    function handleProvinceCity(e) {
        e.preventDefault()
        setProvince(e.target.value)
    }
    return (
        <>
            <div className='landing-page-background'>
                <Toaster />
                <div className='container-img-landing-page' >
                    <img src={Logo} alt="logo" className='img-logo-landing-page'></img>
                </div>
                <div className='container-text-landing-page'> 
                    
                        {/* <p className='first-title-landing-page'>Tanque lleno</p> */}
                        <div className='sub-title' >
                            <h6 className='title-landing-page'>Cansado de no saber</h6>
                            <h6 className='title-landing-page'> dónde cargar nafta?</h6>
                        </div>
                    

                        {provinces.length > 0 ? (<div className='container-select-city-landing-page'>
                        <p style={{  fontWeight: "1000",margin:"0px" }}>Selecciona tu provincia</p>
                        <select className='select-landing' type="name" value={province} onChange={(e) => { handleProvinceCity(e) }}>
                            <option value="" disabled>Elija una provincia</option>
                            {provinces?.map((citi, i) => {
                                return (
                                    <option key={i} value={citi._id}>{citi.province} </option>
                                )
                            })}
                        </select>
                    </div>):(<div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <CircularProgress />
                                <p>Cargando..</p>
                            </div>)}
                    {province.length > 2 &&
                        (
                            <div className='container-select-city-landing-page'>
                                <p style={{  fontWeight: "1000",margin:"0px"  }}>Elija una localidad</p>
                                <select className='select-landing' type="name" value={city} onChange={(e) => { handleSelectCity(e.target.value) }}>
                                    <option value="">Elija una localidad</option>
                                    {provinces.find(localidad => localidad.province.toLocaleLowerCase() === province.toLocaleLowerCase()).cities.map((item, i) => {
                                        return (<option key={i} value={item.city}> {item.city} </option>)

                                    })
                                    }

                                </select>
                            </div>
                        )
                    }

                    <div className='container-select-city-landing-page'>
                        {/* <a href='https://cafecito.app/misfigus' className="text-of-select-city" rel='noopener' target='_blank'><img src='https://cdn.cafecito.app/imgs/buttons/button_5.png' alt='Invitame un café en cafecito.app' /></a> */}
                        <a href="https://forms.gle/XYHAi822GXh6ysVe6" target='_blank'  ><p style={{ textDecoration: "underline", color: "black", fontSize: "14px" }} className="text-of-select-city">Queres agregar una localidad? Contactanos!</p></a>
                        <a href="https://forms.gle/56X8upSBPUG32wYi7" target='_blank'  ><p style={{ textDecoration: "underline", color: "black", fontSize: "14px" }} className="text-of-select-city">Encontraste un error? Reportalo!</p></a>
                    </div>

                </div>
            </div>
        </>
    )
}