import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { FIGUS_API_URL } from '../../constants';

export const userSlice = createSlice({
    name: 'users',
    initialState: {
        list: null,
        selected: [],
        user: [],
        createUser: [],
        stockStore: [],
        commentsSelected: [],
        commentCreated: [],
        createStore: [],
        filtersSelected: { city: "La Plata", available_stock: false },
        selectedCoords: {},
        user_album: [],
        stats_album: [],
        token: [],
        recoveryPassword: [],
        tokenCreateUser: [],
        allProvinces: [],
        globalMap: [],
        globalMarkers: {},
        selectedCityCoords: { lat: -34.921164, lng: -57.954507 },
        provinceAndCitySelected: { province: '', city: '' },
        cityChat:[]

    },
    reducers: {
        googleLogin:(state, action)=>{
            state.user = action.payload;
        },
        setStoreList: (state, action) => {
            state.list = action.payload;
        },
        setAvailableStores: (state, action) => {
            state.list = action.payload;
        },
        setSelected: (state, action) => {
            state.selected = action.payload;
        },
        setEmptySelected: (state) => {
            state.selected = [];
        },
        postLoginUser: (state, action) => {
            state.user = action.payload;
        },
        postCreateUser: (state, action) => {
            state.createUser = action.payload;
        },
        postLoginLocalStorage: (state, action) => {
            state.user = action.payload;
        },
        setStockStore: (state, action) => {
            state.stockStore = action.payload;
        },
        setCreateComment: (state, action) => {
            state.commentCreated = action.payload;
        },
        setGetComment: (state, action) => {
            state.commentsSelected = action.payload;
        },
        setLogOut: (state, action) => {
            state.user = [];
        },
        setCreateStore: (state, action) => {
            state.createStore = action.payload;
        },
        setFilters: (state, action) => {
            state.filtersSelected = action.payload;
        },
        setSelectedCoords: (state, action) => {
            state.selectedCoords = action.payload;
        },
        setGetAlbum: (state, action) => {
            state.user_album = action.payload;
        },
        setStats: (state, action) => {
            state.stats_album = action.payload;
        },
        setToken: (state, action) => {
            state.token = action.payload;
        },
        setRecoveryPassword: (state, action) => {
            state.recoveryPassword = action.payload;
        },
        setTokenCreateUser: (state, action) => {
            state.tokenCreateUser = action.payload;
        },
        setAllProvinces: (state, action) => {
            state.allProvinces = action.payload;
            
        },
        setGlobalMap: (state, action) => {
            state.globalMap = action.payload;
        },
        setGlobalMarkers: (state, action) => {
            state.globalMarkers[action.payload.store_id] = action.payload.marker;
        },
        setSelectedCityCoords: (state, action) => {
            state.selectedCityCoords = action.payload;
        },
        setProvinceAndCitySelected: (state, action) => {
            state.provinceAndCitySelected = action.payload;
        },
        setCityChat : (state, action) => {
            state.cityChat = action.payload;
        },
        
    }
});



export const { googleLogin } = userSlice.actions;
export const { setStoreList } = userSlice.actions;
export const { setSelected } = userSlice.actions;
export const { setEmptySelected } = userSlice.actions;
export const { postLoginUser } = userSlice.actions;
export const { postCreateUser } = userSlice.actions;
export const { postLoginLocalStorage } = userSlice.actions;
export const { setAvailableStores } = userSlice.actions;
export const { setStockStore } = userSlice.actions;
export const { setCreateComment } = userSlice.actions;
export const { setGetComment } = userSlice.actions;
export const { setLogOut } = userSlice.actions;
export const { setCreateStore } = userSlice.actions;
export const { setFilters } = userSlice.actions;
export const { setSelectedCoords } = userSlice.actions;
export const { setGetAlbum } = userSlice.actions;
export const { setStats } = userSlice.actions;
export const { setToken } = userSlice.actions;
export const { setRecoveryPassword } = userSlice.actions;
export const { setTokenCreateUser } = userSlice.actions;
export const { setAllProvinces } = userSlice.actions;
export const { setGlobalMap } = userSlice.actions;
export const { setGlobalMarkers } = userSlice.actions;
export const { setSelectedCityCoords } = userSlice.actions;
export const { setProvinceAndCitySelected } = userSlice.actions;
export const { setCityChat } = userSlice.actions;

export default userSlice.reducer;



export const logOut = () => {
    return (dispatch) => {
        dispatch(setLogOut());
    };
};
export const getCityChat = async (city_id,dispatch) => {
    try {
        const res = await axios.get(`${FIGUS_API_URL}/posts?city_id=${city_id}`);
        dispatch(setCityChat(res.data.data))
        
    } catch (error) {
        console.log(error);
    }
};
export const postCommentChat = async (session_id,city_id,message,dispatch,toast) => {
    try {
        toast.promise(
            createAndGetCommentChat(session_id,city_id,message,dispatch).then(() => { }),
            {
                loading: 'Creando comentario...',
                success: <b>Se ha creado correctamente</b>,
                error: <b>Ha ocurrido un error</b>,
            }
        );
        getCityChat(city_id,dispatch)
    } catch (error) {
        console.log(error);
    }
};
export const createAndGetCommentChat = async (session_id,city_id,message,dispatch) => {
    const res = await axios.post(`${FIGUS_API_URL}/posts?session_id=${session_id}`,{message:message,city_id:city_id});
    await getCityChat(city_id, dispatch);
};
   

export const recoveryPassword = async (mail, password, dispatch, code) => {
    try {
        const res = await axios.post(`${FIGUS_API_URL}/login/recovery`, { mail: mail, password: password, recovery_code: code });
        dispatch(setRecoveryPassword(res.data.data));
    } catch (error) {
        console.log(error);
    }
};


export const verifyTokenCreateUser = async (mail, dispatch, code, session_id, expiry_at) => {


    try {
        const res = await axios.post(`${FIGUS_API_URL}/users/verification`, { mail: mail, verification_code: code });
        dispatch(setTokenCreateUser(res.data.data));
        const res2 = await axios.get(`${FIGUS_API_URL}/users?session_id=${session_id}`);
        dispatch(postLoginLocalStorage({ login: { user: res2.data.data, session_id, expiry_at } }));
        localStorage.setItem('userData', JSON.stringify({ login: { user: res2.data.data, session_id, expiry_at } }));
    } catch (error) {
        console.log(error);
        throw Error(error);
    }
};
export const getAllProvinces = async (dispatch) => {
    try {
        
        const res = await axios.get(`${FIGUS_API_URL}/location/provinces/cities`);
        
        dispatch(setAllProvinces(res.data));
    } catch (error) {
        console.log(error);
    }
};

export const verifyToken = async (mail, dispatch, code) => {
    try {
        const res = await axios.get(`${FIGUS_API_URL}/login/recovery/verify?mail=${mail}&recovery_code=${code}`);
        dispatch(setToken(res.data.data));
    } catch (error) {
        console.log(error);
    }
};

export const getStats = async (session_id, dispatch) => {
    try {
        const res = await axios.get(`${FIGUS_API_URL}/album/metrics?session_id=${session_id}`);
        dispatch(setStats(res.data.data));
    } catch (error) {
        console.log(error);
    }

};
export const putFigurita = async (session_id, user_id, album) => {
    try {
        const res = await axios.put(`${FIGUS_API_URL}/album?session_id=${session_id}`, {
            "album": album,
            "user_id": user_id
        });
    } catch (error) {
        console.log(error);
    }

};

export const getAlbum = async (session_id, dispatch) => {
    try {
        const res = await axios.get(`${FIGUS_API_URL}/album?session_id=${session_id}`);
        dispatch(setGetAlbum(res.data.data.user_album.album));
    } catch (error) {
        console.log(error);
    }
};
export const getEmptySelected = () => {
    return (dispatch) => {
        dispatch(setEmptySelected());
    };
};
export const getAllStores = (available_stock, city, limit) => {
    return (dispatch) => {
        let av_stock = available_stock ? available_stock : null
        let lim = limit ? limit : 100
        let cit = city ? city : 'La Plata'
        axios.get(`${FIGUS_API_URL}/stores`, { params: { available_stock: av_stock, city: cit, limit: lim } }).then((response) => {
            dispatch(setStoreList(response.data.data));
        }).catch((error) => console.log(error));
    };
};
export const getAllStores2 = async (dispatch, storeId) => {

    try {
        const response = await axios.get(`${FIGUS_API_URL}/stores`);
        dispatch(setStoreList(response.data.data));
        dispatch(setSelected(response.data.data.find((e) => e.store_id === storeId)));
    } catch (err) {
        console.log(err);
    }

};

export const getAvaiableStores = () => {
    return (dispatch) => {
        axios.get(`${FIGUS_API_URL}/stores?available_stock=true`).then((response) => {
            dispatch(setAvailableStores(response.data.data));
        }).catch((error) => console.log(error));
    };
};
export const postLogin = (user) => {
    return (dispatch) => {
        axios.post(`${FIGUS_API_URL}/login`, user).then((response) => {
            dispatch(postLoginUser(response.data.data));
        }).catch((error) => console.log(error));
    };
};
export const createUser = (user) => {
    return (dispatch) => {
        axios.post(`${FIGUS_API_URL}/users`, user).then((response) => {
            dispatch(postCreateUser(response.data.data));
        }).catch((error) => console.log(error));
    };
};

export const changeStockStore = async (storeId, availableStock, sessionId, dispatch) => {
    try {
        const response = await axios.put(`${FIGUS_API_URL}/stores/stock/toggle/${storeId}?session_id=${sessionId}`, {
            "available_stock": availableStock
        });

        dispatch(setStockStore(response.data.data));

    } catch (err) {
        console.log(err);
    }
};



export const postLoginLocal = (dataUser) => {
    return (dispatch) => {
        dispatch(postLoginLocalStorage(dataUser));
    };
};

export const getSetCitySelected = (cityCoords) => {
    return (dispatch) => {
        dispatch(setSelectedCoords(cityCoords));
    };
};

export const postFilters = (filter, city, cityCoords, limit) => {
    return (dispatch) => {
        dispatch(setFilters({ filter, city }));
        dispatch(getAllStores(filter, city, limit));
        dispatch(setSelectedCoords(cityCoords));
        dispatch(setSelectedCityCoords(cityCoords));
    };
};

export const getSelected = (place) => {
    return (dispatch) => {
        dispatch(setSelected(place));
        dispatch(setSelectedCoords({ lat: place.latitude, lng: place.longitude }));

    };
};
export const dispatchUpdateAndGetStores = (storeId, availableStock, sessionId, toast, stores) => {
    return (dispatch) => {
        toast.promise(
            updateAndGetStores(storeId, availableStock, sessionId, dispatch, stores).then(() => {

            }),
            {
                loading: 'Actualizando...',
                success: <b>Se ha actualizado correctamente</b>,
                error: <b>Ha ocurrido un error</b>,
            }
        );

    };
};


export const updateAndGetStores = async (storeId, availableStock, sessionId, dispatch, stores) => {
    await changeStockStore(storeId, availableStock, sessionId, dispatch);
    let temporaryStores = JSON.parse(JSON.stringify(stores));
    let indexStore = temporaryStores.findIndex((e) => e.store_id === storeId);
    temporaryStores[indexStore].available_stock = availableStock;
    temporaryStores[indexStore].updated_at = new Date();
    dispatch(setStoreList(temporaryStores));
    dispatch(setSelected(temporaryStores.find((e) => e.store_id === storeId)));
};

export const getComment = async (store_id, dispatch) => {
    const response = await axios.get(`${FIGUS_API_URL}/comments/store/${store_id}`);
    dispatch(setGetComment(response.data.data));
};

export const createComment = async (sessionId, comment, dispatch) => {
    const response = await axios.post(`${FIGUS_API_URL}/comments?session_id=${sessionId}`,
        { store_id: comment.store_id, comment: comment.comment }
    );
    dispatch(setCreateComment(response.data.data));
};




export const dispatchCreateStore = (session_id, store, toast) => {
    return (dispatch) => {
        toast.promise(
            createStore(session_id, store, dispatch).then(() => { }),
            {
                loading: 'Creando tienda...',
                success: <b>Se ha creado correctamente</b>,
                error: <b>Ha ocurrido un error</b>,
            }
        );

    };
};




export const createStore = async (sessionId, store, dispatch, navigate) => {
    const response = await axios.post(`${FIGUS_API_URL}/stores?session_id=${sessionId}`, (store)
    );
    await dispatch(setCreateStore(response.data.data.validation));
};


export const createAndGetComment = async (dispatch, store_id, sessionId, comment) => {
    await createComment(sessionId, comment, dispatch);
    await getComment(store_id, dispatch);
};

export const dispatchCreateAndGetComment = (store_id, sessionId, comment, toast) => {
    return (dispatch) => {
        toast.promise(
            createAndGetComment(dispatch, store_id, sessionId, comment).then(() => { }),
            {
                loading: 'Creando comentario...',
                success: <b>Se ha creado correctamente</b>,
                error: <b>Ha ocurrido un error</b>,
            }
        );
    };
};