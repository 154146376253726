import React from "react";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import Header from "../Header/Header";
import './Login.css'
import {toast, Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { FIGUS_API_URL } from '../../constants';
import axios from "axios";
import { useDispatch } from "react-redux";
import { googleLogin } from "../../store/slices";
import { useGoogleLogin } from '@react-oauth/google';



export default function Login() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  // const client = new OAuth2Client('TU_CLIENT_ID_DE_GOOGLE');
  //   useGoogleOneTapLogin({
  //     onSuccess: credentialResponse => {
  //       console.log(credentialResponse);
  //     },
  //     onError: () => {
  //       console.log('Login Failed');
  //     },
  //   });
  const handleResponseSuccess = async (credentialResponse) => {
    try {
      const response = await fetch(`${FIGUS_API_URL}/users/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          google_token: credentialResponse.credential,
        }),
      });
  
      if (!response.ok) {
        // Si la respuesta del servidor no está en el rango 2xx, lanzar un error
        throw new Error(`Error en la solicitud: ${response.statusText}`);
      }
      
      const data = await response.json();
      localStorage.setItem('userData', JSON.stringify(data));
      // Verificar la estructura de la respuesta y realizar las acciones necesarias
      dispatch(googleLogin(data))
      toast.success("Ha iniciado sesión correctamente");
      
    } catch (error) {
      // Capturar errores y proporcionar mensajes de error específicos al usuario
      console.error(error);
      toast.error("Ha habido un error al iniciar sesión. Intente nuevamente o más tarde");
    }
  };
  
  const handleResponseError = ()=>{
    toast.error("Ha habido un error al iniciar sesión, intente nuevamente o más tarde") ;
  }

  return (
    <>
    <Toaster />
      <GoogleOAuthProvider clientId="1031260233852-v7vsfmsume26984ls81uafqklkdvrn09.apps.googleusercontent.com">
        <div className='login-container-group'>
            <GoogleLogin
            onSuccess={credentialResponse => {
              handleResponseSuccess(credentialResponse);
            }}
          onError={handleResponseError}
          useOneTap
        />
            </div>
                
        
      </GoogleOAuthProvider>
    </>
  );
}
