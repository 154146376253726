
import { useDispatch, useSelector } from 'react-redux';
import { MapContainer, TileLayer} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import CustomMarker from './CustomMarker';
import MapFunctions from './MapFunctions';
import { setGlobalMap } from '../../store/slices';
import { REACT_APP_MAPS_TILES_URL } from '../../constants';


export default function Map({
    setCoordinates,
    setBounds, //FIJARSE SI HACE FALTA USARLO EN LEAFLET
    userCoordinates, //y los otros ver q chota hacen
    isDesktop,
}) {
    const places = useSelector(state => state.users.list);
    const dispatch = useDispatch();
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
            }}
        >
            <div
                style={{
                    height: '85vh',
                    width: '90%',
                    position: 'relative',
                }}
            >
                <MapContainer
                    center={[-34.921164, -57.954507]}
                    zoom={13}
                    scrollWheelZoom={true}
                    whenReady={(map)=>{dispatch(setGlobalMap(map))}}
                >
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        // url="https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png"
                        // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        url={REACT_APP_MAPS_TILES_URL}
                    />
                    <MapFunctions isDesktop={isDesktop}></MapFunctions>

                    {places?.map((place, i) => (
                        <CustomMarker
                            place={place}
                            key={i}
                            isDesktop={isDesktop}
                        ></CustomMarker>
                    ))}
                </MapContainer>
            </div>
        </div>
    );
}
