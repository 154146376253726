import GoogleMapReact from "google-map-react";
import { useState } from "react";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Header from "../Header/Header";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  dispatchCreateStore,
  getAllStores,
  getSetCitySelected,
} from "../../store/slices/index";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getAllProvinces } from "../../store/slices/index";
import { getCityCoords } from "../../services";
import { Link } from "react-router-dom";
import Login from "../Login/Login";

export default function CreateStore() {
  const dispatch = useDispatch();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [province, setProvince] = useState("");
  const provinces = useSelector((state) => state.users.allProvinces);
  const [selectedCity, setSelectedCity] = useState("");
  const user = useSelector((state) => state.users.user.user);
  const [secondPage, setSecondPage] = useState(false);
  const [bounds, setBounds] = useState("");
  const selectedCoordsLanding = useSelector(
    (state) => state.users.selectedCoords
  );
  const defaultCoords = { lat: -34.921164, lng: -57.954507 };
  const [newStore, setNewStore] = useState({
    name: "",
    address: "",
    city_id: "",
    available_stock: false,
    store_type: "",
    image_url: null,
    longitude: "default",
    latitude: "",
  });
  const navigate = useNavigate();
  const sessionId = useSelector((state) => state.users.user.session_id);
  useEffect(() => {
    getAllProvinces(dispatch);
  }, []);

  function validateStoreInputs(newStore) {
    console.log(newStore);
    if (
      !newStore?.name ||
      newStore.name?.length < 1 ||
      newStore.name?.length > 25
    ) {
      toast.error(
        "Por favor ingrese un nombre válido (entre 1 y 25 caracteres)"
      );
      return false;
    }

    if (newStore?.name?.includes("wsp") || newStore?.name?.includes("$")) {
      toast.error("Por favor ingrese un nombre sin precio ni teléfono");
      return false;
    }

    if (
      !newStore?.address ||
      newStore?.address?.length < 3 ||
      newStore?.address?.length > 25
    ) {
      toast.error(
        "Por favor ingrese una dirección válida (entre 3 y 25 caracteres)"
      );
      return false;
    }

    if (Number(newStore.address)) {
      toast.error("Por favor ingrese una dirección válida");
      return false;
    }

    if (newStore?.address?.includes("wsp")) {
      toast.error('Por favor ingrese una dirección sin la abreviatura "wsp"');
      return false;
    }

    if (!newStore?.city_id) {
      toast.error("Por favor seleccione una ciudad");
      return false;
    }

    if (!newStore?.store_type) {
      toast.error("Por favor seleccione un tipo de tienda");
      return false;
    }

    return true;
  }

  function handleUbicationStore(e) {
    e.preventDefault();
    if (validateStoreInputs(newStore)) {
      setButtonDisabled(true);
      dispatch(dispatchCreateStore(sessionId, newStore, toast));
      setTimeout(() => {
        navigate("/home");
        dispatch(getAllStores());
      }, 2000);
    }
  }

  function handleName(e) {
    e.preventDefault();
    const storeAux = { ...newStore, name: e.target.value };
    setNewStore(storeAux);
  }
  function handleCity(e) {
    e.preventDefault();
    setSelectedCity(e.target.value);
    const city_id = provinces
      .find((item) => item.province === province)
      .cities.find((item) => item.city === e.target.value).city_id;
    const storeAux = { ...newStore, city_id: city_id };
    setNewStore(storeAux);

    const cityCoords = getCityCoords(provinces, e.target.value);

    dispatch(getSetCitySelected(cityCoords));
  }
  function handleAdress(e) {
    e.preventDefault();
    const storeAux = { ...newStore, address: e.target.value };
    setNewStore(storeAux);
  }
  function handleType(value) {
    const storeAux = { ...newStore, store_type: value };
    setNewStore(storeAux);
  }
  
  function handleStock(e) {
    const storeAux = {
      ...newStore,
      available_stock: !newStore.available_stock,
    };
    setNewStore(storeAux);
  }
  function handleImg(e) {
    const storeAux = { ...newStore, image_url: e.target.value };
    setNewStore(storeAux);
  }

  function handleSecondPage(e) {
    e.preventDefault();
    if (selectedCity.length > 1) {
      if (
        Math.abs(
          Number(newStore.latitude) - Number(selectedCoordsLanding.lat)
        ) > 0.000001 &&
        Math.abs(
          Number(newStore.longitude) - Number(selectedCoordsLanding.lng)
        ) > 0.000001
      ) {
        setSecondPage(true);
      } else {
        toast.error(
          "Por favor mueva el mapa para seleccionar el lugar donde se encuentra el negocio"
        );
      }
    } else {
      toast.error("Por favor seleccione la ciudad");
    }
  }
  function handleProvinceCity(e) {
    e.preventDefault();
    setProvince(e.target.value);
  }

  return (
    <>
      <Header />
      {user?.name ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: "10px",
          }}
        >
          <div className="login-container">
            {!secondPage ? (
              <>
                <p className="credentials-login">
                  Primero selecciona la provincia
                </p>
                <select
                  className="input-login"
                  type="name"
                  value={province}
                  onChange={(e) => {
                    handleProvinceCity(e);
                  }}
                >
                  <option value="" disabled>
                    Elija una provincia
                  </option>
                  {provinces?.map((citi, i) => {
                    return (
                      <option key={i} value={citi._id}>
                        {citi.province}{" "}
                      </option>
                    );
                  })}
                </select>

                <p className="credentials-login">Ahora selecciona la ciudad</p>
                <select
                  className="input-login"
                  type="name"
                  value={selectedCity}
                  onChange={(e) => {
                    handleCity(e);
                  }}
                >
                  <option value="" disabled>
                    Elige una opción
                  </option>
                  {provinces
                    .filter((localidad) => localidad.province === province)[0]
                    ?.cities.map((item, i) => {
                      return (
                        <option key={i} value={item.city}>
                          {" "}
                          {item.city}{" "}
                        </option>
                      );
                    })}
                </select>
                <p className="credentials-login">Ahora localizala en el mapa</p>
                <div
                  style={{
                    height: "35vh",
                    width: "90%",
                    marginTop: "3rem",
                    position: "relative",
                  }}
                >
                  <GoogleMapReact
                    bootstrapURLKeys={{
                      key: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
                    }}
                    defaultCenter={defaultCoords}
                    center={selectedCoordsLanding}
                    defaultZoom={14}
                    margin={[50, 50, 50, 50]}
                    onChange={(e) => {
                      const storeAux = {
                        ...newStore,
                        longitude: e.center.lng,
                        latitude: e.center.lat,
                      };
                      setNewStore(storeAux);
                      setBounds({
                        ne: e.marginBounds.ne,
                        sw: e.marginBounds.sw,
                      });
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faLocationDot}
                      fontSize={28}
                      color={"red"}
                      style={{ transform: "translate(-50%,-50%)" }}
                    />
                  </GoogleMapReact>
                </div>
                <button
                  className="login-button-to-password"
                  onClick={(e) => handleSecondPage(e)}
                >
                  Continuar
                </button>
              </>
            ) : (
              <>
                <p className="credentials-login">Ingresa el nombre</p>
                <p
                  style={{
                    marginTop: "-10px",
                    fontWeight: "1000",
                    fontSize: "12px",
                    width: "80%",
                  }}
                >
                  (El nombre debe ser representativo, no puede contener precio
                  ni telefono.)
                </p>
                <input
                  type="name"
                  value={newStore.name}
                  onChange={(e) => {
                    handleName(e);
                  }}
                  className="input-login"
                ></input>

                <p className="credentials-login">Ingresa la direccion</p>
                <p
                  style={{
                    marginTop: "-10px",
                    fontWeight: "1000",
                    fontSize: "12px",
                    width: "80%",
                  }}
                >
                  (Es solo de referencia, no hace falta que sea exacta, no se
                  puede ingresar precio ni telefono)
                </p>
                <input
                  type="name"
                  value={newStore.address}
                  onChange={(e) => {
                    handleAdress(e);
                  }}
                  className="input-login"
                ></input>

                <p className="credentials-login">Selecciona la estación</p>
                <select
                  type="tipo"
                  className="input-login"
                  value={newStore.store_type}
                  onChange={(e) => handleType(e.target.value)}
                >
                  <option value="">Elige una opción</option>
                  <option value="ypf">YPF</option>
                  <option value="shell">Shell</option>
                  <option value="axion">Axion Energy</option>
                  <option value="puma">Puma Energy</option>
                  <option value="voy">Voy</option>
                  <option value="otro">Otro</option>
                </select>

                {/* <input type="checked" value={newStore.available} onChange={(e)=>{handleStock(e)}} className='input-login'></input> */}
                <div className="checkbox-container" style={{ padding: "15px" }}>
                  <input
                    type="checkbox"
                    checked={newStore.available_stock}
                    onClick={(e) => {
                      handleStock(e);
                    }}
                  ></input>
                  <p
                    className="sub-title-list"
                    style={{ margin: "0px" }}
                    onClick={(e) => {
                      handleStock(e);
                    }}
                  >
                    {" "}
                    Vende nafta actualmente?
                  </p>
                </div>
                <p
                  style={{
                    marginTop: "-10px",
                    fontWeight: "1000",
                    fontSize: "12px",
                    width: "80%",
                  }}
                >
                  (Selecciona el tilde solo en caso de que ahora mismo venda)
                </p>
                <p className="credentials-login">Ingresa la url de imagen</p>
                <p className="credentials-login" style={{ fontSize: "12px" }}>
                  (No es obligatorio)
                </p>
                <input
                  type="name"
                  value={newStore.image_url}
                  onChange={(e) => {
                    handleImg(e);
                  }}
                  className="input-login"
                ></input>
                <button
                  className="login-button-to-password"
                  onClick={(e) => handleUbicationStore(e)}
                  disabled={buttonDisabled}
                >
                  Finalizar
                </button>
              </>
            )}
          </div>
        </div>
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Para agregar una estación por favor inicie sesión
            <Login />
          </div>
        </div>
      )}
      <Toaster />
    </>
  );
}
