import Header from "../Header/Header";
import './Recovery.css'
import { useEffect, useState } from 'react'
import axios from 'axios'
import {Link} from 'react-router-dom'
import {postLogin, verifyToken,recoveryPassword} from '../../store/slices/index'
import { useDispatch, useSelector } from 'react-redux'
import {useNavigate} from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast';
import { FIGUS_API_URL } from '../../constants'

export default function Recovery(){
    const navigate = useNavigate()
    const [mail,setMail] = useState('')
    const [token,setToken] = useState('')
    const [codigoEnviado,setCodigoEnviado] = useState(false)
    const user = useSelector(state=>state.users.user.user)
    const tokenVerify = useSelector(state=>state.users.token)
    const varRecoveryPassword = useSelector(state=>state.users.recoveryPassword)
    const [tokenVerify2,setTokenVerify2] = useState(false)
    const dispatch = useDispatch()
    const [password,setPassword] = useState('')
    const [passwordRepeat,setPasswordRepeat] = useState('')
    
    useEffect(()=>{
        if (varRecoveryPassword?.validation){
            toast.success('Ha cambiado la contraseña correctamente')
            setTimeout(()=>{
                navigate('/login')
            },2000)
        }else if(varRecoveryPassword?.validation) {
            toast.error('Ha habido un error, intente nuevamente mas tarde')
        }
    },[varRecoveryPassword])

    useEffect(()=>{
        if(tokenVerify?.validation){
            setTokenVerify2(true)
        }
    },[tokenVerify])
    function handleMail(e){
        e.preventDefault()
        setMail(e.target.value)
        
    }
    function onSubmitMail(e){
        e.preventDefault()
        if(mail.length>6){
            axios.get(`${FIGUS_API_URL}/login/recovery?mail=${mail}`)
            setCodigoEnviado(true)
        }else{
            toast.error('Por favor ingrese el mail correctamente')
        }
    }
    function handleTokenSuccess(e){
        e.preventDefault()
        verifyToken(mail,dispatch,token)
    }
    function handleToken(e){
        e.preventDefault()
        if(e.target.value.length<7){
            setToken(e.target.value)
        }
    }
    function handlePassword(e){
        e.preventDefault()
        setPassword(e.target.value)
    }
    function handlePasswordRepeat(e){
        e.preventDefault()
        setPasswordRepeat(e.target.value)
    }

    function handleChangePassword(e){
        e.preventDefault()
        recoveryPassword(mail,password,dispatch,token)
    }
    return(<>
        <Header/>
        <Toaster/>
        {codigoEnviado ?(<div className='login-container-group'>
            {tokenVerify2 ?(
                <form onSubmit={(e)=>{handleChangePassword(e)}}>
                        <div className="login-container" style={{padding:"10px"}}>
                        <p className='credentials-login'>Ingresa la nueva contraseña</p>
                        <input type="password" value={password} onChange={(e)=>{handlePassword(e)}} className='input-login' ></input>
                        <p className='credentials-login'>Repite la contraseña</p>
                        <input type="password" value={passwordRepeat} onChange={(e)=>{handlePasswordRepeat(e)}} className='input-login' ></input>
                        { passwordRepeat !== password ? <p className='text-login-wrong'>Por favor controle que las contraseñas sean iguales</p>:null}
                        <input  className='login-button-to-password'  type="submit" value="Confirmar" />
                </div>
                    </form> 
            ):(<>
                <form onSubmit={(e)=>{handleTokenSuccess(e)}}>
            <div className="login-container" style={{padding:"10px"}}>
                <p className='credentials-login'>El codigo se ha enviado al mail que usted ha ingresado
                </p>
                <p>
                        Ingrese el código recibido</p>
                <input type="name" value={token} onChange={(e)=>{handleToken(e)}} className='input-token-recovery'></input>
                <input  className='login-button-to-password'  type="submit" value="Confirmar" />
            </div>
                </form>
                </>
                )}
        </div>
        
        ):(<>
        <div className='login-container-group' >
                <form onSubmit={(e)=>{onSubmitMail(e)}}>
            <div className="login-container" style={{padding:"10px"}}>
                <p className='credentials-login'>Verificá que esta cuenta te pertenece
                </p>
                <p>
                        Ingresa el e-mail con el que te registraste para recibir el código de verificación.</p>
                <input type="email" value={mail} onChange={(e)=>{handleMail(e)}} className='input-login' placeholder="juanpedro@gmail.com"></input>
                <input  className='login-button-to-password'  type="submit" value="Enviar codigo" />
            </div>
                </form>
                
        </div>
        </>)}
        </>
    )
}