import Header from "../Header/Header";
import CommentChat from "./CommentChat";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getAllProvinces, getCityChat, postCommentChat } from '../../store/slices/index';
import toast, { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";

export default function Chat() {
    const dispatch = useDispatch();
    const [newComment, setNewComment] = useState('');
    const [province, setProvince] = useState('');
    const [city, setCity] = useState('');
    const user = useSelector(state => state.users.user.user);
    const provinces = useSelector(state => state.users.allProvinces);
    const comments = useSelector(state => state.users.cityChat);
    const sessionId = useSelector((state) => state.users.user.session_id);
    const messagesEndRef = useRef(null);
    // const comments = [
    //     {name:'Agustin',
    //     last_name:'Bruno',
    //     comment:'Nos juntamos en plaza italia?',
    //     update_at:'23:32'
    //     },
    //     {name:'Ramiro',
    //     last_name:'Guzman',
    //     comment:'Si dale',
    //     update_at:'23:42'
    //     }
    // ]
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };
    useEffect(() => {
        scrollToBottom();
    }, [comments]);
    function handleSendButton(e) {
        e.preventDefault();
        if (newComment !== '' && city !== '') {
            const city_id = provinces.find(item => item.province === province).cities.find(item => item.city === city).city_id;
            postCommentChat(sessionId, city_id, newComment, dispatch, toast);
            setNewComment('');
        } else {
            if (newComment === '') {
                toast.error('Por favor ingrese un comentario');
            } else {
                toast.error('Por favor seleccione una ciudad');
            }
        }
    }
    function handleProvinceCity(e) {
        if (e.target.value === '') return;
        e.preventDefault();
        setProvince(e.target.value);
    }
    function handleCity(e) {
        if (e.target.value === '') return;
        e.preventDefault();
        setCity(e.target.value);
        const city_id = provinces.find(item => item.province === province).cities.find(item => item.city === e.target.value).city_id;
        getCityChat(city_id, dispatch);
        window.scroll({
            top: 2000,
            behavior: 'smooth',
        });
    }

    useEffect(() => {
        getAllProvinces(dispatch);

    }, []);


    return (
        <>
            <Toaster />
            <Header />
            {user?.name ? (
                <div className="container-all-comment-chat">
                    <h2>Envie un mensaje para coordinar el intercambio de figuritas!</h2>
                    {provinces ? (<>
                        <p className='credentials-chat'>Primero selecciona la provincia</p>
                        <select className='input-chat' type="name" value={province} onChange={(e) => { handleProvinceCity(e); }}>
                            <option value="">Elija una provincia</option>
                            {provinces?.map((citi, i) => {
                                return (
                                    <option key={i} value={citi._id}>{citi.province} </option>
                                );
                            })}
                        </select>

                        <p className='credentials-chat'>Ahora selecciona la ciudad</p>
                        <select className='input-chat' type="name" value={city} onChange={(e) => { handleCity(e); }}>
                            <option value="">Elige una opción</option>
                            {provinces?.filter(localidad => localidad.province === province)[0]?.cities.map((item, i) => {
                                return (<option key={item.city} value={item.city}> {item.city} </option>);

                            })
                            }
                        </select>
                    </>
                    ) : (null)}
                    {city.length > 0 ? (<h3>Chat de la ciudad de : {city}</h3>) : (null)}
                    <div className="container-comments-chat">
                        {comments.length > 0 ? (
                            comments?.map((comment, i) => {
                                return (
                                    <CommentChat key={i} comment={comment} />
                                );
                            })
                        ) : (<div>Aun no hay comentarios, sé el primero!</div>)}
                        <div ref={messagesEndRef}>

                        </div>
                    </div>
                    <form className="container-send-chat" onSubmit={(e) => { handleSendButton(e); }}>
                        <input className="input-msg-chat" placeholder="Mensaje" value={newComment} onChange={(e) => { setNewComment(e.target.value); }}></input>
                        <button className="send-button-chat" type="submit">
                            <div className="send-image-button">
                                <svg viewBox="0 0 24 24" width="24" height="24" ><path fill="white" d="M1.101 21.757 23.8 12.028 1.101 2.3l.011 7.912 13.623 1.816-13.623 1.817-.011 7.912z"></path></svg>
                            </div>
                        </button>
                    </form>

                </div>
            ) : (
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", paddingTop: "15px" }}>
                    <Link to="/login">
                        <button
                            className="button-accept-modal"
                            style={{ width: '250px' }}
                        >
                            Por favor Inicie sesion
                        </button>
                    </Link>
                </div>
            )}
        </>
    );
}