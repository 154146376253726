/** @format */

import { useSelector, useDispatch } from "react-redux";
import Header from "../Header/Header";
import "./Profile.css";
import UserIcon from "../../svg/user.svg";
import Album from "../../svg/album.svg";
import Location from "../../svg/location.svg";
import Next from "../../svg/next.svg";
import Exit from "../../svg/exit.svg";
import { Link, useNavigate } from "react-router-dom";
import { logOut } from "../../store/slices";
import Login from "../Login/Login";

export default function Profile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const UserLogo =
    "https://www.mercadolibre.com/org-img/mkt/email-mkt-assets/my-profile/privacidadIcon.svg";
  const user = useSelector((state) => state.users.user.user);

  function handleLogOut(e) {
    e.preventDefault();
    // dispatch(deleteUser(user.email))
    localStorage.removeItem("userData");
    dispatch(logOut());
    navigate("/home");
  }

  return (
    <>
      <Header />
      {user?.name ? (
        <div className="container-profile">
          <div className="container-div-name-profile">
            {user.picture ? (
              <div className="container-user-profile">
                <img alt="profile" src={user.picture}></img>
                <h3>
                  {user?.name} {user?.last_name}
                </h3>
              </div>
            ) : (
              <div className="container-user-profile">
                <div className="profile-picture-profile" alt="">
                  <p>{user?.name[0].toUpperCase()}</p>
                  <p> {user?.last_name[0].toUpperCase()}</p>
                </div>
                <h3>
                  {user?.name} {user?.last_name}
                </h3>
              </div>
            )}

            {user?.isAdmin ? (
              <>
                <Link
                  style={{
                    color: "black",
                    textDecoration: "none",
                  }}
                  to="/admin/createProduct"
                >
                  <div className="container-options-profile">
                    <div className="container-img-profile">
                      <img src={UserLogo} className="img-div-profile" alt="" />
                    </div>
                    <div className="text-div-profile">
                      <h4>Crear producto</h4>
                      <h6>Permite la creacion de un producto</h6>
                    </div>
                    <img className="button-next-profile" src={Next} alt="" />
                  </div>
                </Link>
                <Link
                  style={{
                    color: "black",
                    textDecoration: "none",
                  }}
                  to="/admin/edit"
                >
                  <div className="container-options-profile">
                    <div className="container-img-profile">
                      <img src={UserLogo} className="img-div-profile" alt="" />
                    </div>
                    <div className="text-div-profile">
                      <h4>Editar y eliminar producto</h4>
                      <h6>Permite la edicion,o eliminacion de un producto</h6>
                    </div>
                    <img src={Next} className="button-next-profile" alt="" />
                  </div>
                </Link>
                <Link
                  style={{
                    color: "black",
                    textDecoration: "none",
                  }}
                  to="/admin/createCategory"
                >
                  <div className="container-options-profile">
                    <div className="container-img-profile">
                      <img src={UserLogo} className="img-div-profile" alt="" />
                    </div>
                    <div className="text-div-profile">
                      <h4>Crear o Eliminar Categoria</h4>
                      <h6>
                        Permite la creacion o eliminacion de una categoria
                      </h6>
                    </div>
                    <img
                      src={Next}
                      className="button-next-profile"
                      alt=""
                    ></img>
                  </div>
                </Link>
                <Link
                  style={{
                    color: "black",
                    textDecoration: "none",
                  }}
                  to="/admin/orders"
                >
                  <div className="container-options-profile">
                    <div className="container-img-profile">
                      <img src={UserLogo} className="img-div-profile" alt="" />
                    </div>
                    <div className="text-div-profile">
                      <h4>Ver Ordenes</h4>
                      <h6>Permite ver, editar,o eliminar ordenes</h6>
                    </div>
                    <img src={Next} className="button-next-profile" alt="" />
                  </div>
                </Link>
                <Link
                  style={{
                    color: "black",
                    textDecoration: "none",
                  }}
                  to="/admin/roles"
                >
                  <div className="container-options-profile">
                    <div className="container-img-profile">
                      <img src={UserLogo} className="img-div-profile" alt="" />
                    </div>
                    <div className="text-div-profile">
                      <h4>Administrar roles</h4>
                      <h6>Permite administrar roles de administrador</h6>
                    </div>
                    <img src={Next} className="button-next-profile" alt="" />
                  </div>
                </Link>
              </>
            ) : null}
          </div>

          <div className="container-div-data-profile">
            <Link
              style={{ color: "black", textDecoration: "none" }}
              to="/misdatos"
            >
              <div className="container-options-profile">
                <div className="container-img-profile">
                  <img src={UserIcon} className="img-div-profile" alt="" />
                </div>

                <div className="text-div-profile">
                  <h4>Mis Datos</h4>
                </div>

                <img src={Next} className="button-next-profile" alt=""></img>
              </div>
            </Link>

            <Link
              style={{ color: "black", textDecoration: "none" }}
              to="/createStore"
            >
              <div className="container-options-profile">
                <div className="container-img-profile">
                  <img src={Location} className="img-div-profile" alt="" />
                </div>
                <div className="text-div-profile">
                  <h4>Registra una estación</h4>
                </div>
                <img src={Next} className="button-next-profile" alt=""></img>
              </div>
            </Link>
            {/* <Link
                        style={{ color: "black", textDecoration: "none" }}
                        to="/album"
                    >
                        <div className="container-options-profile">
                            <div className="container-img-profile">
                                <img
                                    src={Album}
                                    className="img-div-profile"
                                    alt=""
                                />
                            </div>
                            <div className="text-div-profile">
                                <h4>Mi album y repetidas</h4>
                            </div>
                            <img
                                src={Next}
                                className='button-next-profile'
                                alt=""
                            ></img>
                        </div>
                    </Link> */}
            <div
              style={{
                color: "black",
                textDecoration: "none",
                cursor: "pointer",
              }}
              onClick={(e) => handleLogOut(e)}
            >
              <div className="container-options-profile">
                <div className="container-img-profile">
                  <img src={Exit} className="img-div-profile" alt="" />
                </div>
                <div className="text-div-profile">
                  <h4>Desloguearse</h4>
                </div>
                <img src={Next} className="button-next-profile" alt=""></img>
              </div>
            </div>
            {/* <Link
                        style={{ color: "black", textDecoration: "none" }}
                        to="/user/adress"
                    >
                        <div className="container-options-profile">
                            <div className="container-img-profile">
                                <img
                                    src="https://www.mercadolibre.com/org-img/mkt/email-mkt-assets/my-profile/direccionesIcon.svg"
                                    className="img-div-profile"
                                    alt=""
                                />
                            </div>
                            <div className="text-div-profile">
                                <h4>Direcciones</h4>
                                <h6>Aca podras administrar tus direcciones</h6>
                            </div>
                            <img
                                src={Next}
                                className='button-next-profile'
                                alt=""
                            ></img>
                        </div>
                    </Link> */}
            {/* <button style={{backgroundColor:"#fff",marginTop:"1rem",padding:"1rem",}} onClick={handleEliminate}>
                        <div className="container-options-profile">
                            <div className="container-img-profile">
                                <img
                                    src={Exclamation}
                                    className="img-div-profile"
                                    alt=""
                                />
                            </div>
                            <div className="text-div-profile">
                                <h4>Eliminar cuenta</h4>
                                <h6>Elimina tu cuenta para siempre</h6>
                            </div>
                            <img
                                src={Next}
                                className='button-next-profile'
                                alt=""
                            ></img>
                        </div>
                    </button> */}
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <p className="text-modal">
            Primero debe iniciar sesión
          </p>
          <Login></Login>
        </div>
      )}
    </>
  );
}
