import axios from "axios";
import { FIGUS_API_URL } from "../../constants";
import { setStockStore } from "../../store/slices";
import { useSelector, useStore } from "react-redux";
import toast, { Toaster } from "react-hot-toast";

export default function StoreIssue({ store }) {
  const sessionId = useSelector((state) => state.users.user.session_id);

  async function handleClickeado() {
    try {
      await axios.put(
        `${FIGUS_API_URL}/stores/toggle/${store.store_id}?session_id=${sessionId}&active=false`
      );
      toast.success("Se ha borrado con exito la tienda");
    } catch {
      toast.error("Ha ocurrido un error");
    }
  }
  async function handleStockTienda() {
    try {
      const newStock = !store.available_stock;
      await axios.put(
        `${FIGUS_API_URL}/stores/stock/toggle/${store.store_id}?session_id=${sessionId}&available_stock=${newStock}`
      );
      toast.success("Se ha cambiado el stock de la tienda con exito");
    } catch {
      toast.error("Ha ocurrido un error");
    }
  }
  return (
    <>
      <Toaster />

      <div style={{ display: "flex", flexDirection: "row",alignItems:'center'}}>
        <div>
            {store?.active === false && <div> ELIMINADA</div>}
            <div className="img-place-map">
            <img src={store?.image_url} alt="imagen de store"></img>
            </div>
        </div>

        <div className="info-store-modified" style={{margin:"2rem"}}>
          <h4 style={{padding:"0px",margin:"0px"}}>Nombre: {store?.name}</h4>
          <h5 style={{padding:"0px",margin:"0px"}}>Provincia: {store?.province}</h5>
          <h5 style={{padding:"0px",margin:"0px"}}>Ciudad: {store?.city}</h5>
          <h5 style={{padding:"0px",margin:"0px"}}>Direccion: {store?.address}</h5>
          <h6 style={{padding:"0px",margin:"0px"}}>Estado: {store?.available_stock ? "Hay stock" : "No hay stock"}</h6>
        </div>
      
        <div className="last-user-store-modified">
            <h5>Ultimo usuario en actualizar</h5>
            <p>
            {store.last_user.name} {store.last_user.last_name}
            </p>
            <p>{store.last_user.mail}</p>
            <p>{store.last_user.verified}</p>
        </div>
        <div>
            <button
            onClick={() => {
                handleStockTienda();
            }}
            >
            Cambiar stock
            </button>
            <button
            onClick={() => {
                handleClickeado();
            }}
            >
            {store.active === true ? 'Borrar store':'Activar store'}
            </button>
        </div>
      </div>
    </>
  );
}
