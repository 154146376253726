import { useSelector } from "react-redux";
import Header from "../Header/Header";
import "./Admin.css";
import axios from "axios";
import { useState } from "react";
import Issue from "./Issue";
import { useEffect } from "react";
import { FIGUS_API_URL } from "../../constants";
import toast,{Toaster} from "react-hot-toast";

export default function Admin() {
  const user = useSelector((state) => state.users.user.user);
  const sessionId = useSelector((state) => state.users.user.session_id);
  const [stores, setStores] = useState("");
  const [issues, setIssues] = useState("");
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(issues.length / 5);
  const [firstIndex, setFirstIndex] = useState(0);
  const [lastIndex, setLastIndex] = useState(firstIndex + 5);

  const handleRevisado = async (issue_id) => {
    try {
      await axios.put(`${FIGUS_API_URL}/issues/${issue_id}/toggle?session_id=${sessionId}&active=false`);
      toast.success('Se ha pasado el issue a revisado')
      // Filtra los issues activos y actualiza el estado
      setIssues((prevIssues) => prevIssues.filter((issue) => issue.issue_id !== issue_id));
      console.log(issues)
    } catch (error) {
      console.error("Error al actualizar el estado del problema:", error);
    }
  };

  useEffect(() => {
    const fetchIssues = async () => {
      let issuesActive; // Declara issuesActive en un ámbito más amplio
  
      try {
        const response = await axios.get(
          `${FIGUS_API_URL}/issues?session_id=${sessionId}`
        );
  
        if (response.data.message === "Successful Request") {
          issuesActive = response.data.data.filter((item) => item?.active);
          setIssues(issuesActive);
          setLastPage(Math.ceil(issuesActive.length / 5));
        }
  
        const peticiones = issuesActive.slice(firstIndex,lastIndex).map((issue) => {
          return axios.get(`${FIGUS_API_URL}/stores/${issue.store_id}`);
        });
  
        const responses = await Promise.all(peticiones);
        const storesData = responses.map((response) => response.data.data);
        setStores(storesData);
      } catch (error) {
        console.error("Error al obtener las tiendas:", error);
      }
    };
  
    if (sessionId) {
      fetchIssues();
    }
  }, [sessionId,firstIndex,lastIndex]);
  

  return issues.length > 0 && stores.length > 0 ? (
    <>
        <Toaster/>
      <Header />
      <h1 className="container-issues">Reportes</h1>
      <div className="container-issues">
        {issues?.slice(firstIndex, lastIndex).map((issue, i) => {
          return (
              <Issue
                key={i}
                issue={issue}
                store={stores[i]}
                handleRevisado={handleRevisado}
              />
          );
        })}
      </div>
      <div className="container-issues">
        {page !== 1 && (
          <button
            onClick={() => {
              setFirstIndex(firstIndex - 5);
              setLastIndex(lastIndex - 5);
              setPage(page - 1);
            }}
          >
            Pagina anterior
          </button>
        )}
        <span>
          {page * 5} de {issues.length}
        </span>
        {lastPage > page && (
          <button
            onClick={() => {
              setFirstIndex(firstIndex + 5);
              setLastIndex(lastIndex + 5);
              setPage(page + 1);
            }}
          >
            Proxima pagina
          </button>
        )}
      </div>
    </>
  ) : (
    <div>Cargando...</div>
  );
}
